<template>
  <v-container
    fluid
    class="light-blue lighten-5 pb-16 px-1"
  >
    <v-row class="mb-0 d-flex justify-center align-center">
      <v-col
        cols="12"
        sm="12"
        md="9"
        class="pb-0 mb-0"
      >
        <v-card
          elevation="0"
          class="white px-auto"
        >
          <v-stepper>
            <v-stepper-header>
              <v-stepper-step
                step="1"
                complete
                complete-icon="mdi-home-circle"
              >
                RENTAL INFO
              </v-stepper-step>

              <v-divider></v-divider>

              <!-- <v-stepper-step
                step="2"
                complete
                complete-icon="mdi-home-circle"
              >
                CHOOSE PLAN
              </v-stepper-step> -->

              <v-divider></v-divider>

              <v-stepper-step
                step="2"
                complete
                complete-icon="mdi-home-circle"
              >
                PROPERTY INFO
              </v-stepper-step>

              <v-divider></v-divider>
              <v-stepper-step
                step="3"
                complete
                complete-icon="mdi-home-circle"
              >
                COVERAGE
              </v-stepper-step>

              <v-divider></v-divider>

              <v-stepper-step
                step="4"
                complete
                complete-icon="mdi-home-circle"
              >
                PURCHASE PLAN
              </v-stepper-step>
              <v-divider></v-divider>
              <v-stepper-step
                step="5"
                complete
                complete-icon="mdi-home-circle"
              >
                YOUR POLICY
              </v-stepper-step>
            </v-stepper-header>
          </v-stepper>
        </v-card>
      </v-col>
    </v-row>
    <v-row class="d-flex justify-center mt-0 pb-16">
      <v-col
        cols="12"
        sm="12"
        md="9"
        class="mt-0 pt-0"
      >
        <v-card class="mt-0 pt-0">
          <v-row
            v-if="loading"
            class="mt-12"
          >
            <v-col class="d-flex justify-center">
              <ellipse-progress
                :loading="loading"
                :size="180"
                img-src="src/assets/images/renters-icon.png"
                thickness="5"
                empty-thickness="3"
                line-mode="out 5"
                legend="false"
                animation="rs 700 1000"
                font-size="1.5rem"
                color="#00A1B7"
                empty-color-fill="transparent"
                filename="renters-icon.png"
              >
              </ellipse-progress>
            </v-col>
          </v-row>
          <div v-if="!loading && !error">
            <v-row>
              <v-col class="d-inline-flex align-center mt-16 justify-center">
                <v-img
                  src="/img/celebration 1.svg"
                  :height="mobileDevice ? '40px' : '64px'"
                  :max-width="mobileDevice ? '40px' : '64px'"
                  class="mr-4"
                ></v-img>
                <div :class="mobileDevice ? 'title-text-mobile' : 'title-text'">
                  Congratulations!
                </div>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <p class="title-subtext">
                  Thank you for your purchase.
                </p>
              </v-col>
            </v-row>
            <v-row class="d-flex justify-center">
              <v-col
                cols="12"
                sm="11"
                md="11"
                lg="10"
              >
                <v-card elevation="0">
                  <div v-show="!mobileDevice">
                    <v-row class="grey-background grey-outline table-fields">
                      <v-col cols="3">
                        Provider
                      </v-col>
                      <v-col cols="3">
                        Effective Date
                      </v-col>
                      <v-col cols="3">
                        Premium Cost
                      </v-col>
                      <v-col cols="3">
                        Policy No.
                      </v-col>
                    </v-row>
                    <v-row class="grey-outline table-fields">
                      <v-col cols="3">
                        STILLWATER
                      </v-col>
                      <!-- <v-col cols="3">
                        {{ policy.product[0] }}
                      </v-col> -->
                      <v-col cols="3">
                        {{ effectiveDate }}
                      </v-col>
                      <v-col cols="3">
                        <v-chip class="primary">
                          <span
                            class="table-fields white--text"
                          >$ {{ price }}</span>
                        </v-chip>
                      </v-col>
                      <v-col cols="3">
                        {{ policyNumber }}
                      </v-col>
                    </v-row>
                  </div>
                  <div v-if="mobileDevice">
                    <v-row class="table-fields d-flex justify-center">
                      <v-col
                        cols="11"
                        class="grey-background grey-outline"
                      >
                        <v-col cols="12">
                          Provider: STILLWATER
                        </v-col>
                        <v-col cols="12">
                          Effective Date : {{ effectiveDate }}
                        </v-col>
                        <v-col cols="12">
                          Premium Cost :
                          <v-chip class="primary">
                            <span
                              class="table-fields white--text"
                            >${{ price }}</span>
                          </v-chip>
                        </v-col>
                        <v-col cols="12">
                          Policy No. : {{ policyNumber }}
                        </v-col>
                      </v-col>
                    </v-row>
                  </div>
                </v-card>
              </v-col>
            </v-row>
            <v-row class="d-flex justify-center">
              <v-col
                cols="11"
                class="d-flex justify-center"
              >
                <div class="policy-txt">
                  This policy has been added to Your Blanket Account. You can
                  view all of your policies under
                  <a
                    class="policy-txt primary--text"
                    @click="
                      toUserPolcies();
                      clickedFieldGlobal(
                        'dlYourPolicy viewMyPolicies',
                        129,
                        'Renters Survey v1'
                      );
                    "
                  >
                    My Policies.
                  </a>
                </div>
              </v-col>
            </v-row>
            <v-row>
              <v-col
                :class="mobileDevice ? 'justify-center' : 'justify-end pa-6'"
                class="d-flex"
              >
                <v-btn
                  large
                  right
                  class="primary font-weight-bold mt-4 mb-2"
                  to="/userpolicies"
                  @click="
                    clickedFieldGlobal(
                      'dlYourPolicy viewMyPolicies',
                      130,
                      'Renters Survey v1'
                    )
                  "
                >
                  View My Policies
                </v-btn>
              </v-col>
            </v-row>
          </div>
        </v-card>
      </v-col>
    </v-row>
    <v-dialog
      v-model="errorDialog"
      max-width="700"
      persistent
    >
      <v-card class="pa-2">
        <v-card-title class="justify-center font-weight-bold text-h5">
          We are unable to process your request
        </v-card-title>
        <v-card-text class="justify-center text-center body primmary--text">
          <v-row>
            <v-col>
              We’re sorry that we are unable to process your application at this time due to the following error:
            </v-col>
          </v-row>
          <v-row>
            <v-col class="font-italic">
              {{ error }}
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-row
            class="d-flex justify-center"
            no-gutters
          >
            <v-col class="d-flex justify-center">
              <v-btn
                color="primary"
                class="mb-4 mt-4 mr-0 font-weight-bold"
                to="/renters/info"
                @click="clearError"
              >
                Restart Application
              </v-btn>
            </v-col>
            <v-col class="d-flex justify-center">
              <v-btn
                color="primary"
                class="mb-4 mt-4 mr-0 font-weight-bold"
                to="/"
                @click="clearError"
              >
                Return to Home
              </v-btn>
            </v-col>
          </v-row>
        </v-card-actions>

        <v-row
          no-gutters
          class="d-flex justify-center font-weight-bold text-h5"
        >
          <v-col class="d-flex justify-center font-weight-bold text-center">
            Please contact us at 1-(888)-333-3145 for support with your application.
          </v-col>
        </v-row>
      </v-card>
    </v-dialog>
  </v-container>
</template>
<script>
  import EllipseProgress from '../components/EllipseProgress.vue'
  export default {
    components: {
      EllipseProgress
    },
    data () {
      return {
        errorDialog: false
      }
    },
    computed: {
      error () {
        return this.$store.getters.error
      },
      mobileDevice () {
        return this.$vuetify.breakpoint.xsOnly
      },
      tabletDevice () {
        return this.$vuetify.breakpoint.smOnly
      },
      loading () {
        return this.$store.getters.getloading
      },
      policy () {
        let policy = ''
        policy = this.$store.getters.getRentalPolicy
        return policy
      },
      policyNumber () {
        let polnum = ''
        polnum = this.policy.ACORD.InsuranceSvcRs[0].HomePolicyAddRs[0]
          .PolicySummaryInfo[0].PolicyNumber[0]
        return polnum
      },
      effectiveDate () {
        let date = ''
        date = this.policy.ACORD.InsuranceSvcRs[0].HomePolicyAddRs[0]
          .PolicySummaryInfo[0].EffectiveDt[0]
        return date
      },
      price () {
        let price = 0
        price = this.policy.ACORD.InsuranceSvcRs[0].HomePolicyAddRs[0]
          .PolicySummaryInfo[0].FullTermAmt[0].Amt[0]
        return price
      }
    },
    watch: {
      error (newValue) {
        console.log('error changed:', newValue)
        this.errorDialog = !!newValue
      }
    },
    methods: {
      clearError () {
        this.$store.dispatch('clearError')
        this.errorDialog = false
      },
      toUserPolcies () {
        this.$router.push('/userpolicies')
      }
    }
  }
</script>
<style lang="scss" scoped>
.table-fields {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 20px;
  /* identical to box height, or 125% */

  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: 0.4px;
  color: #757575;
}

.table-fields-mobile {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 20px;
  /* identical to box height, or 125% */

  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: 0.4px;
  color: #757575;
}

.title-text {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 40px;
  line-height: 40px;
  text-align: center;
  letter-spacing: 0.02em;
  color: #00a1b7;
}

.title-text-mobile {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 30px;
  line-height: 40px;
  text-align: center;
  letter-spacing: 0.02em;
  color: #00a1b7;
}

.grey-background {
  background-color: #f5f5f5;
}

.grey-outline {
  border: 1px solid #e0e0e0;
}

.title-subtext {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 22px;
  text-align: center;
  letter-spacing: 0.1px;
  color: #212121;
}

.policy-txt {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 140%;
  /* or 18px */

  letter-spacing: -0.04em;

  /* Gray/600 */

  color: #757575;
}
</style>
